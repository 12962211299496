export const ticketFiscalConstants = {
    //Crear
    TICKET_FISCAL_CREATE_REQUEST: 'TICKET_FISCAL_CREATE_REQUEST',
    TICKET_FISCAL_CREATE_SUCCESS: 'TICKET_FISCAL_CREATE_SUCCESS',
    TICKET_FISCAL_CREATE_FAILURE: 'TICKET_FISCAL_CREATE_FAILURE',

    TICKET_FISCAL_TABLE_REQUEST: 'TICKET_FISCAL_TABLE_REQUEST',
    TICKET_FISCAL_TABLE_SUCCESS: 'TICKET_FISCAL_TABLE_SUCCESS',
    TICKET_FISCAL_TABLE_FAILURE: 'TICKET_FISCAL_TABLE_FAILURE',

    TICKET_FISCAL_UPDATE_REQUEST: 'TICKET_FISCAL_UPDATE_REQUEST',
    TICKET_FISCAL_UPDATE_SUCCESS: 'TICKET_FISCAL_UPDATE_SUCCESS',
    TICKET_FISCAL_UPDATE_FAILURE: 'TICKET_FISCAL_UPDATE_FAILURE',

    TICKET_FISCAL_DELETE_REQUEST: 'TICKET_FISCAL_DELETE_REQUEST',
    TICKET_FISCAL_DELETE_SUCCESS: 'TICKET_FISCAL_DELETE_SUCCESS',
    TICKET_FISCAL_DELETE_FAILURE: 'TICKET_FISCAL_DELETE_FAILURE',
};
