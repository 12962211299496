/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
  return(
    <footer className="footer" style={{marginLeft:'70px'}}>
      <Container>
        {<nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Diseñado por Fattoria Fbm.{" "}
          </div>
          <div>
            FBM Fattoria System Version 1.7
          </div>
        </nav>}
      </Container>
    </footer>
  )    
}

export default TransparentFooter;
