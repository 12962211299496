/* eslint-disable */
import { ticketFiscalConstants } from '../constants/ticketFiscal.constants'
import { ticketFiscalService } from '../services/ticketFiscal.service';
import { alertActions } from './';

export const ticketFiscalActions = {

    dataTable(agency) {
        return dispatch => {
            dispatch(request());

            ticketFiscalService.ticketTable(agency)
                .then(
                    ticket => {
                        dispatch(success(ticket))
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };

        function request() { return { type: ticketFiscalConstants.TICKET_FISCAL_TABLE_REQUEST } }
        function success(ticket) { return { type: ticketFiscalConstants.TICKET_FISCAL_TABLE_SUCCESS, ticket } }
        function failure(error) { return { type: ticketFiscalConstants.TICKET_FISCAL_TABLE_FAILURE, error } }
    },

    dataTableWholesale(agency) {
        return dispatch => {
            dispatch(request());

            ticketFiscalService.ticketTableWholesale(agency)
                .then(
                    ticket => {
                        dispatch(success(ticket))
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };

        function request() { return { type: ticketFiscalConstants.TICKET_FISCAL_TABLE_REQUEST } }
        function success(ticket) { return { type: ticketFiscalConstants.TICKET_FISCAL_TABLE_SUCCESS, ticket } }
        function failure(error) { return { type: ticketFiscalConstants.TICKET_FISCAL_TABLE_FAILURE, error } }
    },

    //Registrar ticket
    createTicket(ticket) {
        return dispatch => {

            dispatch(request(ticket));

            ticketFiscalService.ticketCreate(ticket)
                .then(
                    ticket => { 
                        dispatch(success(ticket));
                        dispatch(alertActions.success('¡Se ha registrado venta en espera correctamente!'));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };

        function request(ticket) { return { type: ticketFiscalConstants.TICKET_FISCAL_CREATE_REQUEST, ticket } }
        function success(ticket) { return { type: ticketFiscalConstants.TICKET_FISCAL_CREATE_SUCCESS, ticket } }
        function failure(error) { return { type: ticketFiscalConstants.TICKET_FISCAL_CREATE_FAILURE, error } }
    },

    //editar ticket
    updateTicket(id, data) {
        return dispatch => {
            dispatch(request(id));
    
            ticketFiscalService.updateTicketData(id,data)
                .then(
                    () => {
                        dispatch(success());
                        dispatch(alertActions.success('¡Se ha actualizado la venta en espera correctamente!'));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };
    
        function request(id) { return { type: ticketFiscalConstants.TICKET_FISCAL_UPDATE_REQUEST, id } }
        function success() { return { type: ticketFiscalConstants.TICKET_FISCAL_UPDATE_SUCCESS } }
        function failure(error) { return { type: ticketFiscalConstants.TICKET_FISCAL_UPDATE_FAILURE, error } }
    },

    //eliminar ticket
    removeTicket(id, agency) {
        return dispatch => {
            dispatch(request(id));
    
            ticketFiscalService.removeTicket(id, agency)
                .then(
                    ticket => {
                        dispatch(success(ticket));
                        //actualizar data en el reducer de la tabla
                        dispatch(updateData(ticket));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };
    
        function request(id) { return { type: ticketFiscalConstants.TICKET_FISCAL_DELETE_REQUEST, id } }
        function success(ticket) { return { type: ticketFiscalConstants.TICKET_FISCAL_DELETE_SUCCESS, ticket } }
        function updateData(ticket) { return { type: ticketFiscalConstants.TICKET_FISCAL_TABLE_SUCCESS, ticket } }
        function failure(error) { return { type: ticketFiscalConstants.TICKET_FISCAL_DELETE_FAILURE, error } }
    },



};
